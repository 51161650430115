<template>
  <b-modal
    id="create-or-update-registration-modal"
    v-model="$show"
    centered
    scrollable
    size="xl"
    header-class="modal__header"
    footer-class="modal__footer"
    body-class="modal__body"
    no-close-on-backdrop
    @hidden="onHidden"
  >
    <template #modal-header="{}">
      <h3 class="font-weight-bold">ข้อมูลการสมัครสมาชิก</h3>
      <span v-if="editData && editData.updatedAt"
        >อัพเดตล่าสุด:
        {{ $_formatDateMixin_formatShort(editData.updatedAt) }}</span
      >
    </template>

    <b-alert
      :show="
        form.status === 'รออนุมัติ' || form.status === 'รอการยืนยันการชำระเงิน'
      "
      class="d-flex align-items-center pl-2 align-content-center"
      variant="warning"
    >
      <span class="font-size-lg d-block d-40 mr-2 text-center">
        <font-awesome-icon icon="question-circle" />
      </span>

      <span>กรุณาตรวจสอบข้อมูลการสมัครก่อนการอนุมัติหรือยืนยันการชำระเงิน</span>
    </b-alert>

    <b-form-row v-if="form.registration_code">
      <div class="py-2 col-12 col-md-6">
        <h5>รหัสสมัครสมาชิก: {{ form.registration_code }}</h5>

        <h5 class="mt-3" :class="getStatusClass(form.status)">
          สถานะ: {{ form.status }}
        </h5>

        <div v-if="editData.approveByUser" class="mt-2">
          อนุมัติการสมัครโดย: {{ getUserName(editData.approveByUser) }}
        </div>
        <div
          v-if="form.status === 'ไม่ผ่านการอนุมัติ' && editData.rejectByUser"
          class="mt-2"
        >
          ปฏิเสธการสมัครโดย: {{ getUserName(editData.rejectByUser) }}

          <div class="mt-2">
            <span>เหตุผลการปฏิเสธ: </span>
            <span>{{ editData.reject_cause }}</span>
          </div>
        </div>
        <div
          v-if="form.status === 'ลงทะเบียนสำเร็จ' && editData.confirmByUser"
          class="mt-2"
        >
          ยืนยันการชำระเงินโดย: {{ getUserName(editData.confirmByUser) }}
        </div>
      </div>
    </b-form-row>

    <b-form-row>
      <div class="py-2 col-12 col-md-3">
        <label for="tel">เบอร์โทรศัพท์</label>

        <b-form-input
          id="text-phone"
          type="tel"
          class="form-control"
          name="tel"
          placeholder="0xx-xxx-xxxx"
          v-mask="'0##-###-####'"
          :disabled="true"
          v-model="form.phone_number"
          :state="
            invalid
              ? $v.form.phone_number &&
                $v.form.phone_number.required &&
                $v.form.phone_number.minLength &&
                $v.form.phone_number.maxLength
              : null
          "
        />

        <b-form-invalid-feedback id="text-phone-feedback">
          <span v-if="$v.form.phone_number && !$v.form.phone_number.required">
            กรุณากรอกเบอร์โทรศัพท์
          </span>

          <span
            v-else-if="
              $v.form.phone_number &&
              (!$v.form.phone_number.minLength ||
                !$v.form.phone_number.maxLength)
            "
          >
            เบอร์โทรศัพท์ไม่ถูกต้อง
          </span>
        </b-form-invalid-feedback>
      </div>

      <div class="col-md-3 col-12 py-2">
        <label class="font-weight-bold" for="rank">ยศ</label>
        <v-select
          id="rank"
          v-model="rank"
          :options="ranks"
          :loading="isFetchingRank"
        ></v-select>

        <!-- <small
          v-if="
            invalid &&
            validation.form.mRankId &&
            !validation.form.mRankId.required
          "
          class="text-danger"
        >
          กรุณาเลือกยศ
        </small> -->
      </div>

      <div class="py-2 col-12 col-md-3">
        <label for="text-first_name">ชื่อ</label>

        <b-form-input
          type="text"
          id="text-first_name"
          v-model="form.first_name"
          :state="invalid ? $v.form.first_name.required : null"
        ></b-form-input>

        <b-form-invalid-feedback id="text-first_name-feedback">
          กรุณากรอกชื่อ
        </b-form-invalid-feedback>
      </div>
      <div class="py-2 col-12 col-md-3">
        <label for="text-last_name">นามสกุล</label>

        <b-form-input
          type="text"
          id="text-last_name"
          v-model="form.last_name"
          :state="invalid ? $v.form.last_name.required : null"
        ></b-form-input>

        <b-form-invalid-feedback id="text-last_name-feedback">
          กรุณากรอกนามสกุล
        </b-form-invalid-feedback>
      </div>
      <div class="py-2 col-12 col-md-4">
        <label for="BDate">(วัน เดือน ปี เกิด)</label>

        <datepicker
          ref="datepicker"
          id="BDate"
          input-class="form-control"
          v-model="birthDate"
          required
          :language="th"
          :format="formatBuddhistBirthDate"
        ></datepicker>

        <small
          v-if="invalid && $v.form.bd_date && !$v.form.bd_date.required"
          class="text-danger"
        >
          กรุณาเลือกวัน/เดือน/ปีเกิด
        </small>
      </div>
      <!-- <div class="py-2 col-12 col-md-3">
        <label for="text-age">อายุ (ปี)</label>

        <b-form-input
          type="number"
          min="0"
          id="text-age"
          v-model="form.age"
          :state="invalid ? $v.form.age.required : null"
        ></b-form-input>

        <b-form-invalid-feedback id="text-age-feedback">
          กรุณากรอกอายุ
        </b-form-invalid-feedback>
      </div> -->
      <div class="py-2 col-12 col-md-4">
        <label for="id_card">เลขบัตรประชาชน</label>

        <b-form-input
          id="id_card"
          :state="
            invalid
              ? $v.form.id_card &&
                $v.form.id_card.required &&
                $v.form.id_card.valid
              : null
          "
          type="text"
          placeholder="x-xxxx-xxxxx-xx-x"
          required
          v-mask="'#-####-#####-##-#'"
          v-model="form.id_card"
        ></b-form-input>

        <b-form-invalid-feedback id="id_card-feedback">
          <span v-if="$v.form.id_card && !$v.form.id_card.required">
            กรุณากรอกเลขบัตรประชาชน
          </span>

          <span v-else-if="$v.form.id_card && !$v.form.id_card.valid">
            เลขบัตรประชาชนไม่ถูกต้อง
          </span>
          <!-- <span>เลขบัตรประชาชนไม่ถูกต้อง</span> -->
        </b-form-invalid-feedback>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-email">E-mail</label>

        <b-form-input
          v-model="form.email"
          type="email"
          id="text-email"
          :state="invalid ? $v.form.email.email : null"
        ></b-form-input>

        <b-form-invalid-feedback id="text-age-feedback">
          อีเมลไม่ถูกต้อง
        </b-form-invalid-feedback>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-work-at">สถานที่ทำงาน</label>
        <b-form-input
          type="text"
          min="0"
          id="text-work-at"
          v-model="form.workplace_name"
        ></b-form-input>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-position_name">ตำแหน่ง</label>
        <b-form-input
          type="text"
          id="text-position_name"
          v-model="form.position_name"
        ></b-form-input>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-affiliation">สังกัด</label>
        <b-form-input
          type="text"
          id="text-affiliation"
          v-model="form.affiliation_name"
        ></b-form-input>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-work-at">จังหวัด</label>

        <v-select
          id="provinces"
          label="name_th"
          v-model="form.province"
          :options="provinces"
          @input="onInputProvince"
        ></v-select>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-amphures">อำเภอ</label>
        <v-select
          id="text-amphures"
          label="name_th"
          v-model="form.amphur"
          :options="amphures"
          @input="onInputAmphure"
        ></v-select>
      </div>

      <div class="py-2 col-12 col-md-4">
        <label for="text-district">ตำบล</label>
        <v-select
          id="text-district"
          label="name_th"
          v-model="form.district"
          :options="tombons"
          :reduce="(tombons) => tombons.name_th"
        ></v-select>
      </div>

      <div class="py-2 col-12">
        <label for="text-address">ที่อยู่ปัจจุบัน</label>
        <b-form-textarea
          id="textarea"
          v-model="form.address"
          placeholder=""
          rows="3"
          max-rows="6"
        ></b-form-textarea>
      </div>

      <div class="pt-5 col-12">
        <h5>หลักฐานประกอบการสมัคร</h5>
      </div>

      <div class="py-3 col-12 col-md-4">
        <label for="text-individual_img_url">รูปถ่ายขนาด 1 นิ้ว</label>
        <b-form-row>
          <image-card
            v-if="form.individual_img_url"
            :image="form.individual_img_url"
          ></image-card>

          <b-card v-else style="height: 190px" class="w-100 text-center"
            ><b-card-text class="mt-5"
              >ยังไม่ได้ทำการอัพโหลด</b-card-text
            ></b-card
          >
        </b-form-row>
      </div>

      <div class="py-3 col-12 col-md-4">
        <label for="text-id_card_img_url">รูปสำเนาบัตรประชาชน</label>
        <b-form-row>
          <image-card
            v-if="form.id_card_img_url"
            :image="form.id_card_img_url"
          ></image-card>

          <b-card v-else style="height: 190px" class="w-100 text-center"
            ><b-card-text class="mt-5"
              >ยังไม่ได้ทำการอัพโหลด</b-card-text
            ></b-card
          >
        </b-form-row>
      </div>

      <div class="py-3 col-12 col-md-4">
        <label for="text-id_card_img_url"
          >รูปหลักฐานการชำระเงินค่าสมัครสมาชิก</label
        >
        <b-form-row>
          <image-card
            v-if="form.payment_img_url"
            :image="form.payment_img_url"
          ></image-card>

          <b-card v-else style="height: 190px" class="w-100 text-center"
            ><b-card-text class="mt-5"
              >ยังไม่ได้ทำการอัพโหลด</b-card-text
            ></b-card
          >
        </b-form-row>
      </div>
    </b-form-row>

    <template #modal-footer="{ cancel }">
      <b-button
        size="sm"
        variant="dark"
        :disabled="isSubmiting"
        @click="cancel()"
      >
        ยกเลิก
      </b-button>

      <b-button
        size="sm"
        variant="primary"
        @click.prevent="save"
        :disabled="isSubmiting"
      >
        <b-spinner
          v-if="isSubmiting && isUpdating"
          small
          label="submiting..."
        ></b-spinner>
        <span v-else>อัพเดตข้อมูล</span>
      </b-button>

      <b-button
        v-if="form.status === 'รออนุมัติ'"
        size="sm"
        variant="success"
        :disabled="isSubmiting"
        @click.prevent="updateStatusWaitingForPayment"
      >
        <b-spinner
          v-if="isSubmiting && isUpdatingStatusWaitingForPayment"
          small
          label="submiting..."
        ></b-spinner>
        <span v-else>อนุมัติ</span>
      </b-button>

      <b-button
        v-if="form.status === 'รอการยืนยันการชำระเงิน'"
        size="sm"
        variant="success"
        :disabled="isSubmiting"
        @click.prevent="updateApprove"
      >
        <b-spinner
          v-if="isSubmiting && isUpdatingApprove"
          small
          label="submiting..."
        ></b-spinner>
        <span v-else>ยืนยันการชำระเงิน</span>
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import { validationMixin } from "vuelidate";
import {
  email,
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import formatDateMixin from "../../mixins/formatDateMixin";
import validatorMixin from "../../mixins/validatorMixin";
import { formatBuddhistBirthDate } from "../../helpers/datepicker-helper";
import { th } from "vuejs-datepicker/dist/locale";
import Datepicker from "vuejs-datepicker";
// import TeacherForm from "../form/Teacher";
// import FilterMaster from "../form/FilterMaster";
// import UserInformationForm from "../form/UserInformation";
import { Auth, MRank, Registration } from "../../models";
import ImageCard from "../carousel/ImageCard";

export default {
  mixins: [formatDateMixin, validatorMixin, validationMixin],

  components: {
    Datepicker,
    vSelect,
    ImageCard,
  },

  props: {
    show: Boolean,

    editData: {
      type: Object,
      default: () => ({}),
    },

    disabledInput: {
      type: Object,
      default: () => ({}),
    },
  },

  model: {
    prop: "show",
    event: "update",
  },

  data() {
    return {
      th,
      isSubmiting: false,
      isUpdating: false,
      isUpdatingStatusWaitingForPayment: false,
      isUpdatingApprove: false,
      isFetchingRank: false,
      invalid: false,
      rank: null,
      provinces: [],
      amphures: [],
      tombons: [],
      birthDate: this.editData.bd_date
        ? new Date(this.editData.bd_date).setFullYear(
            new Date(this.editData.bd_date).getFullYear() - 543
          )
        : null,
      form: {
        phone_number: this.editData.phone_number || "",
        first_name: this.editData.first_name || "",
        last_name: this.editData.last_name || "",
        bd_date: this.editData.bd_date || "",
        // age: this.editData.age || null,
        id_card: this.editData.id_card || "",
        workplace_name: this.editData.workplace_name || "",
        position_name: this.editData.position_name || "",
        affiliation_name: this.editData.affiliation_name || "",
        address: this.editData.address || "",
        province: this.editData.province || "",
        amphur: this.editData.amphur || "",
        district: this.editData.district || "",
        email: this.editData.email || "",
        individual_img_url: this.editData.individual_img_url || null,
        id_card_img_url: this.editData.id_card_img_url || null,
        payment_img_url: this.editData.payment_img_url || null,
        registration_code: this.editData.registration_code || null,
        status: this.editData.status || null,
        m_rank_id: this.editData.m_rank_id || null,
      },
    };
  },

  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      bd_date: { required },
      phone_number: {
        required,
        minLength: minLength(12),
        maxLength: maxLength(12),
      },
      id_card: {
        required,
        valid: (v) => {
          return v && v.length
            ? validatorMixin.methods.$_validatorMixin_chkDigitPid(v)
            : true;
        },
      },
      // age: { required },
      //   status: { required },
      email: { email },
    },
  },

  computed: {
    authUser() {
      return Auth.user();
    },

    $show: {
      get() {
        return this.show;
      },

      set(v) {
        this.$emit("update", v);
      },
    },

    ranks() {
      return MRank.all().map((record) => ({
        id: record.id,
        label: record.m_rank_name,
      }));
    },
  },

  watch: {
    $show(v) {
      if (v) {
        this.syncForm();
        this.init();
      }
    },

    birthDate: {
      handler: "onBirthDateChanged",
    },

    rank: {
      handler: "onRankChanged",
    },
  },

  methods: {
    formatBuddhistBirthDate,

    onBirthDateChanged(date) {
      if (typeof date === "object" && date !== null) {
        const dObj = new Date(date.getTime());

        dObj.setFullYear(dObj.getFullYear() + 543);
        dObj.setHours(0);
        dObj.setMinutes(0);
        dObj.setSeconds(0);

        this.$set(this.form, "bd_date", dObj.toISOString());
      }
    },

    syncForm() {
      this.birthDate = this.editData.bd_date
        ? new Date(this.editData.bd_date).setFullYear(
            new Date(this.editData.bd_date).getFullYear() - 543
          )
        : null;

      this.form = {
        phone_number: this.editData.phone_number || "",
        first_name: this.editData.first_name || "",
        last_name: this.editData.last_name || "",
        bd_date: this.editData.bd_date || "",
        // age: this.editData.age || null,
        id_card: this.editData.id_card || "",
        workplace_name: this.editData.workplace_name || "",
        position_name: this.editData.position_name || "",
        affiliation_name: this.editData.affiliation_name || "",
        address: this.editData.address || "",
        province: this.editData.province || "",
        amphur: this.editData.amphur || "",
        district: this.editData.district || "",
        email: this.editData.email || "",
        individual_img_url: this.editData.individual_img_url || null,
        id_card_img_url: this.editData.id_card_img_url || null,
        payment_img_url: this.editData.payment_img_url || null,
        registration_code: this.editData.registration_code || null,
        status: this.editData.status || null,
        m_rank_id: this.editData.m_rank_id || null,
      };
    },

    async init() {
      await Promise.all([this.fetchRanks(), this.getProvinces()]);
    },

    // onBirthDateChanged(date) {
    //   const dObj = new Date(date.getTime());

    //   dObj.setFullYear(dObj.getFullYear() + 543);
    //   dObj.setHours(0);
    //   dObj.setMinutes(0);
    //   dObj.setSeconds(0);

    //   this.$set(this.form, "bd_date", dObj.toISOString());
    // },

    async save() {
      this.$v.$touch();

      this.invalid = false;

      if (this.$v.$invalid) {
        this.invalid = true;
        return this.$toast.error(`กรุณากรอกข้อมูลให้ครบถ้วน`);
      }

      const {
        phone_number,
        first_name,
        last_name,
        bd_date,
        age,
        email,
        workplace_name,
        position_name,
        affiliation_name,
        address,
        province,
        amphur,
        district,
        individual_img_url,
        id_card_img_url,
        payment_img_url,
        m_rank_id,
      } = this.form;

      //   const formData = {
      //     ...this.form,
      //     phone_number: this.form.phone_number.replace(/-/g, ""),
      //   };

      this.isSubmiting = true;
      this.isUpdating = true;

      try {
        if (this.editData && this.editData.id) {
          await Registration.api().update(this.editData.id, {
            phone_number: phone_number.replace(/-/g, ""),
            first_name: first_name.trim(),
            last_name: last_name.trim(),
            bd_date,
            age,
            email,
            workplace_name,
            position_name,
            affiliation_name,
            address,
            province,
            amphur,
            district,
            individual_img_url,
            id_card_img_url,
            payment_img_url,
            m_rank_id,
          });
        }

        this.$toast.success(
          `${
            this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
          }ข้อมูลการสมัครสมาชิกเรียบร้อยแล้ว`
        );

        this.$emit("create:success");
      } catch (error) {
        this.$toast.error(
          error.response.data.message ||
            `${
              this.editData && this.editData.id ? "อัพเดต" : "สร้าง"
            }ข้อมูลการสมัครสมาชิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
        );
      } finally {
        this.isSubmiting = false;
        this.isUpdating = false;
      }
    },

    async updateStatusWaitingForPayment() {
      this.isSubmiting = true;
      this.isUpdatingStatusWaitingForPayment = true;

      try {
        if (this.editData && this.editData.id) {
          await Registration.api().updateStatusWaitingForPayment(
            this.editData.id,
            { approve_by: this.authUser.id }
          );

          axios.post(
            `${process.env.VUE_APP_OTP_ENDPOINT}/api/send-message`,
            {
              sender: "NSBPolice",
              phone: this.form.phone_number.replace(/-/g, ""),
              message: `การสมัครสมาชิกชมรมแดร์ประเทศไทยหมายเลข: ${this.editData.registration_code} ได้รับการอนุมัติแล้ว กรุณาชำระเงินค่าสมัครสมาชิก 100 บาท และอัพโหลดหลักฐานการชำระเงิน: <TRACKING-URL>`,
              url: `${process.env.VUE_APP_FRONTEND_URL}/registers/detail?code=${this.editData.registration_code}`,
            },
            {
              headers: {
                api_key: process.env.VUE_APP_OTP_API_KEY__SEND,
                secret_key: process.env.VUE_APP_OTP_SECRET_KEY__SEND,
              },
            }
          );
        }

        this.$toast.success("อนุมัติการสมัครสมาชิกเรียบร้อยแล้ว");

        this.$show = false;

        this.$emit("create:success");
      } catch (error) {
        this.$toast.error(
          error.response.data.message ||
            `อนุมัติการสมัครสมาชิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
        );
      } finally {
        this.isSubmiting = false;
        this.isUpdatingStatusWaitingForPayment = false;
      }
    },

    async updateApprove() {
      this.isSubmiting = true;
      this.isUpdatingApprove = true;

      try {
        if (this.editData && this.editData.id) {
          await Registration.api().updateApprove(this.editData.id, {
            confirm_by: this.authUser.id,
          });

          axios.post(
            `${process.env.VUE_APP_OTP_ENDPOINT}/api/send-message`,
            {
              sender: "NSBPolice",
              phone: this.form.phone_number.replace(/-/g, ""),
              message: `การสมัครสมาชิกชมรมแดร์ประเทศไทยหมายเลข: ${this.editData.registration_code} ได้รับการยืนยันการชำระเงินแล้ว ขอบคุณสำหรับการสมัครสมาชิกชมรมแดร์ประเทศไทย`,
              // url: `${process.env.VUE_APP_FRONTEND_URL}/registers/detail?code=${this.editData.registration_code}`,
            },
            {
              headers: {
                api_key: process.env.VUE_APP_OTP_API_KEY__SEND,
                secret_key: process.env.VUE_APP_OTP_SECRET_KEY__SEND,
              },
            }
          );
        }

        this.$toast.success("อนุมัติการสมัครสมาชิกเรียบร้อยแล้ว");

        this.$show = false;

        this.$emit("create:success");
      } catch (error) {
        this.$toast.error(
          error.response.data.message ||
            `อนุมัติการสมัครสมาชิกไม่สำเร็จ กรุณาลองใหม่อีกครั้ง`
        );
      } finally {
        this.isSubmiting = false;
        this.isUpdatingApprove = false;
      }
    },

    onHidden() {
      this.clearData();
    },

    clearData() {
      this.rank = null;
      this.birthDate = null;
      this.provinces = [];
      this.amphures = [];
      this.tombons = [];
      this.invalid = false;

      this.form = {};

      this.$emit("update:editData", {});
    },

    async getProvinces() {
      let { data } = await axios.get(
        "https://raw.githubusercontent.com/kongvut/thai-province-data/master/api_province_with_amphure_tombon.json"
      );

      if (data) {
        this.provinces = data;
      }
    },

    onInputProvince(value) {
      this.$set(
        this.form,
        "province",
        value && value.name_th ? value.name_th : ""
      );
      this.$set(this.form, "amphur", "");
      this.$set(this.form, "district", "");

      this.amphures = value && value.amphure ? value.amphure : [];
    },

    onInputAmphure(value) {
      this.$set(
        this.form,
        "amphur",
        value && value.name_th ? value.name_th : ""
      );
      this.$set(this.form, "district", "");

      this.tombons = value && value.tombon ? value.tombon : [];
    },

    getStatusClass(status) {
      return {
        "text-success": status === "ลงทะเบียนสำเร็จ",
        "text-danger": status === "ไม่ผ่านการอนุมัติ",
      };
    },

    getUserName(user) {
      const { m_rank, first_name, last_name } = user;

      let name = `${first_name} ${last_name}`;

      if (m_rank) {
        name = `${m_rank.m_rank_name} ${name}`;
      }

      return name;
    },

    async fetchRanks() {
      this.isFetchingRank = true;

      try {
        await MRank.api().findAll();

        if (this.editData) {
          const { m_rank_id } = this.editData;
          console.log(m_rank_id);
          const mRank = MRank.find(m_rank_id);

          if (mRank) {
            this.rank = {
              id: mRank.id,
              label: mRank.m_rank_name,
            };
          }
        }
      } catch (error) {
        console.log(error);
        this.$toast.error("ไม่สามารถโหลดข้อมูลตำแหน่งได้ กรุณาลองใหม่อีกครั้ง");
      } finally {
        this.isFetchingRank = false;
      }
    },

    onRankChanged(rank = null) {
      let id = null;

      if (rank && rank.id) {
        id = rank.id;
      }

      this.$set(this.form, "m_rank_id", id);
    },
  },
};
</script>
